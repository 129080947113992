<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card layout-toppanel">
        <p>
          <i class="pi pi-fw pi-info-circle"></i> Se gestiona todos las trámites
          de entrada
        </p>
      </div>
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            
          </template>

          <template v-slot:right>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          v-model:selection="selectedProducts"
          selectionMode="single"
          dataKey="id"
          sortField="fechaHora"
          :sortOrder="1"
          :paginator="true"
          :rows="10"
          v-model:filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} items"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'solicitud.nroTramite',
            'solicitud.nombre',
            'solicitud.apellidos',
          ]"
        >
          <template #header>
            <div
              class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              "
            >
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <Column selectionMode="single" headerStyle="width: 3rem"></Column>

          <Column
            field="nroTramite"
            header="Nro.Tramite"
            :sortable="true"
            headerStyle="width: 4rem"
          >
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              <b>{{ slotProps.data.solicitud.nroTramite }}</b>
            </template>
          </Column>

          <Column field="fechaHora" header="Fecha Sol" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha Sol</span>
              <b>{{ formatFecha(slotProps.data.solicitud.fechaHora) }}</b>
            </template>
          </Column>

          <Column field="solicitante" header="Solicitante" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Abrev.</span>
              {{ slotProps.data.solicitud.apellidos }}
              {{ slotProps.data.solicitud.nombre }}
            </template>
          </Column>

          <Column field="usuaOrigen" header="Usuario Origen" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Usuario Origen</span>
              {{ slotProps.data.usuaDetalleOrigen }}
            </template>
          </Column>
          <Column
            field="dedeOrigen"
            header="Dependencia Origen"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Dependencia Origen</span>
              {{ slotProps.data.ofiDetalleOrigen }}
            </template>
          </Column>
          <Column field="tipo" header="Proceso" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Proceso</span>
              {{ showEstado(slotProps.data.tipo) }} <br />{{
                showRecepcion(slotProps.data.fechaHoraRecepcion)
              }}
            </template>
          </Column>

          <Column headerStyle="width: 3rem">
            <template #body="slotProps">
              <Button
                icon="pi pi-ellipsis-v"
                @click="[itemClick(slotProps.data), toggle($event)]"
              />
              <Menu ref="menu" :model="items" :popup="true">
                <template #item="{ item }">
                  <Menuitem :rel="slotProps">
                    <li>{{ item.label }}</li>
                  </Menuitem>
                </template>
              </Menu>
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="derivarDialog"
          :style="{ width: '550px' }"
          header="Seleccione la dependencia y usuario al cual derivar"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="name">Dependencia o sede</label>

            <Dropdown
              v-model="selectEjecutora"
              required="true"
              :options="ejecutora"
              :filter="true"
              optionLabel="nombre"
              @change="changeDepe()"
              :disabled="isBussy"
              placeholder="Seleccione la sede o dependencia"
              :class="{ 'p-invalid': submitted && !selectEjecutora }"
              filterPlaceholder="Busque la dependencia"
            />
            <small class="p-invalid" v-if="submitted && !selectEjecutora"
              >Seleccione la dependencia o sede.</small
            >
          </div>
          <div class="p-field">
            <label for="name">Usuario o trabajador</label>

            <Dropdown
              v-model="selectUsuario"
              required="true"
              :options="listusuario"
              :filter="true"
              optionLabel="nombre"
              :disabled="isBussy"
              placeholder="Seleccione la usuario o trabajador"
              :class="{ 'p-invalid': submitted && !selectUsuario }"
              filterPlaceholder="Busque el usuario"
            />
            <small class="p-invalid" v-if="submitted && !selectUsuario"
              >Seleccione la usuario o trabajador.</small
            >
          </div>
          <div class="p-field">
            <label>Observacion:</label>
            <Textarea
              v-model="dObservacion"
              required="true"
              rows="3"
              cols="90"
            />
          </div>

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Enviar"
              icon="pi pi-check"
              class="p-button-text"
              @click="enviarProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirmar"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="selectedProducts"
              >Está seguro de querer borrar el item:
              <b>{{ selectedProducts.id }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Si"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="finalizarDialog"
          :style="{ width: '650px' }"
          header="Confirmar"
          :modal="true"
        >
          <h5>Observacion</h5>
          <Textarea v-model="observacion" required="true" rows="3" cols="90" />

          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="selectedProducts"
              >Está seguro de querer archivar o finalizar el item:
              <b>{{ selectedProducts.solicitud.nroTramite }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="finalizarDialog = false"
            />
            <Button
              label="Si"
              icon="pi pi-check"
              class="p-button-text"
              @click="finalizarProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="displayVisor"
          :style="{ width: '1024px' }"
          header="Ver detalles"
          :modal="true"
          class="p-fluid"
        >
          <template #header>
            <h5>Expediente: {{ selectedProducts.solicitud.nroTramite }}</h5>
          </template>

          <div class="p-field">
            <div class="p-grid p-fluid dashboard">
              <div class="p-col-6 p-md-6 p-lg-6">
                <h5>Numero de Trámitee</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.nroTramite"
                >
                </InputText>
              </div>

              <div class="p-col-6 p-md-6 p-lg-6">
                <h5>Fecha Hora</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.fechaHora"
                >
                </InputText>
              </div>

              <div class="p-col-12 p-md-12 p-lg-12">
                <h5>TUPA Dependencia</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.pago.tupa.ejecutora.nombre"
                >
                </InputText>
              </div>
              <div class="p-col-12 p-md-12 p-lg-12">
                <h5>Item TUPA</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.pago.tupa.nombre"
                >
                </InputText>
              </div>
              <div class="p-col-6 p-md-6 p-lg-6">
                <h5>Solicitante DNI</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.nroDni"
                >
                </InputText>
              </div>
              <div class="p-col-6 p-md-6 p-lg-6">
                <h5>Solicitante RUC</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.nroRuc"
                >
                </InputText>
              </div>

              <div class="p-col-6 p-md-6 p-lg-6">
                <h5>Nombres</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.nombre"
                >
                </InputText>
              </div>

              <div class="p-col-6 p-md-6 p-lg-6">
                <h5>Apellidos</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.apellidos"
                >
                </InputText>
              </div>

              <div class="p-col-6 p-md-6 p-lg-6">
                <h5>Correo</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.correo"
                >
                </InputText>
              </div>

              <div class="p-col-6 p-md-6 p-lg-6">
                <h5>Telefono</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.telefono"
                >
                </InputText>
              </div>

              <div class="p-col-4 p-md-4 p-lg-4">
                <h5>Región</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.region"
                >
                </InputText>
              </div>

              <div class="p-col-4 p-md-4 p-lg-4">
                <h5>Provincia</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.provincia"
                >
                </InputText>
              </div>

              <div class="p-col-4 p-md-4 p-lg-4">
                <h5>Distrito</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.distrito"
                >
                </InputText>
              </div>

              <div class="p-col-6 p-md-6 p-lg-6">
                <h5>Dirección</h5>
                <InputText
                  :readonly="true"
                  v-model="selectSolicitud.direccion"
                >
                </InputText>
              </div>

              <div class="p-col-6 p-md-6 p-lg-6">
                <h5>Recepción del contenido</h5>
                <label class="form-label">Correo: </label>
                <Checkbox v-model="selectSolicitud.recibirCorreo" value = "si"/>
                <label class="form-label">Físico: </label>
                <Checkbox  v-model="selectSolicitud.recibirFisico" value = "si" />
              </div>

              <div class="p-col-12 p-lg-12">
              <DataTable
                ref="dt"
                v-model:selection="select"
                :value="selectSolicitud.detalle"
                :paginator="true"
                :rows="10"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5, 10, 25]"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} multimedias"
                responsiveLayout="scroll"
              >
                <template #header>
                  <div
                    class="
                      table-header
                      p-d-flex p-flex-column p-flex-md-row p-jc-md-between
                    "
                  >
                    <label>Lista de arhivos adjuntados</label>
                  </div>
                </template>
                <Column
                  selectionMode="single"
                  headerStyle="width: 3rem"
                ></Column>
                <Column header="Archivo">
                  <template #body="slotProps">
                    <span class="p-column-title">archivo</span>
                    {{ slotProps.data.nombre }}
                  </template>
                </Column>
                <Column field="name" header="Descripción" :sortable="true">
                  <template #body="slotProps">
                    <span class="p-column-title">Extension</span>
                    {{ slotProps.data.extension }}
                  </template>
                </Column>
                <Column headerStyle="width: 120px">
                  <template #body="slotProps">
                    <a target="_BLANK"
                      :href="slotProps.data.rutaArchivo"
                    >ver </a>
                  </template>
                </Column>
              </DataTable>
            </div>


            </div>

            
          
          </div>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  data() {
    return {
      page: 1,
      numPages: 0,
      errors: [],
      ejecutora: [],
      displayVisor: false,
      listusuario: [],
      selectUsuario: null,
      select: null,
      dObservacion: "",
      scale: "page-width",
      finalizarDialog: false,
      derivarDialog: false,
      selectEjecutora: {},
      selectSolicitud: { pago:{tupa:{ejecutora:{nombre:""}}}, recibirCorreo: false, recibirFisico:false},

      items: [
        {
          label: "Opciones",
          items: [
            {
              label: "Ver",
              icon: "pi pi-fw pi-eye",
              command: () => {
                this.ver(this.selectedProducts);
              },
            },
            {
              label: "Recepcionar",
              icon: "pi pi-fw pi-check",
              command: () => {
                this.recepcionar(this.selectedProducts);
              },
            },
            {
              label: "Derivar",
              icon: "pi pi-fw pi-arrow-right",
              command: () => {
                this.derivar(this.selectedProducts);
              },
            },
            {
              label: "Finalizar",
              icon: "pi pi-fw pi-briefcase",
              command: () => {
                this.finalizar(this.selectedProducts);
              },
            },
          ],
        },
      ],
      observacion: "",

      products: null,
      productDialog: false,
      deleteProductDialog: false,
      product: {},
      selectedProducts: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nombreObra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      isBusy: false,
      submitted: false,
      action: "gstupa/DEPENDENCIA_SAVE",
    };
  },

  methods: {
    openFile(value){
      console.info(value)
    },
    enviarProduct() {
      this.submitted = true;

      if (this.selectUsuario && this.selectEjecutora) {
        let mod = {
          id: this.product.id,
          dede: this.selectEjecutora.idEjecutora,
          usua: this.selectUsuario.usuaId,
          text: this.dObservacion,
        };
        this.isBusy = true;
        this.$swal({
          width: 370,
          text: "derivando espere porfavor...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch("gstupa/TRAMITE_DERIVAR", mod)
              .then((res) => {
                console.info(res);
                this.$swal.close();
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail:
                    "Item derivador con id: " +
                    this.product.solicitud.nroTramite,
                  life: 5000,
                });
                this.derivarDialog = false;
                this.listarEntrada();
              })
              .catch((error) => {
                console.info(error);
                this.$swal.close();
              });
          },
        }).then((result) => {
          console.log(result);
        });
      }
    },
    listarEjecutora(e) {
      this.isBussy = true;
      this.$store
        .dispatch("gstupa/DEPENDENCIA_LIST", e === undefined ? true : e)
        .then((res) => {
          this.isBussy = false;
          this.ejecutora = res;
        })
        .catch((error) => {
          this.isBussy = false;
          this.error(error);
        });
    },
    listarUsuario(e) {
      this.isBussy = true;
      this.$store
        .dispatch("gstupa/DEPENDENCIA_USUA_LIST", e === undefined ? true : e)
        .then((res) => {
          this.isBussy = false;
          this.listusuario = res;
        })
        .catch((error) => {
          this.isBussy = false;
          this.error(error);
        });
    },
    derivar(value) {
      this.product = { ...value };

      if (this.product.procesado == false) {
        this.$toast.add({
          severity: "error",
          summary: "Advertencia",
          detail: "debe recepcionar primero !",
          life: 3000,
        });
      } else {
        this.derivarDialog = true;
      }
    },
    changeDepe() {
      this.listarUsuario(this.selectEjecutora.idEjecutora);
    },
    finalizarProduct() {
      if (this.product.procesado == true) {
        let model = {
          id: this.product.id,
          text: this.observacion,
        };
        this.$swal({
          width: 370,
          text: "archivando esperer porfavor...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch("gstupa/TRAMITE_FINALIZAR", model)
              .then((res) => {
                console.info(res);
                this.$swal.close();
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail:
                    "Item archivado con id: " +
                    this.product.solicitud.nroTramite,
                  life: 5000,
                });
                this.listarEntrada();
              })
              .catch((error) => {
                console.info(error);
                this.$swal.close();
              });
          },
        }).then((result) => {
          console.log(result);
        });
      }
      this.finalizarDialog = false;
    },
    itemClick(data) {
      this.selectedProducts = data;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    recepcionar(value) {
      this.product = { ...value };
      console.info(this.product);
      if (this.product.procesado == false) {
        this.$swal({
          width: 370,
          text: "recepcionando esperar porfavor...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch("gstupa/TRAMITE_RECEPCIONAR", this.product.id)
              .then((res) => {
                console.info(res);
                this.$swal.close();
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail:
                    "Item recepcionado con id: " +
                    this.product.solicitud.nroTramite,
                  life: 5000,
                });
                this.listarEntrada();
              })
              .catch((error) => {
                console.info(error);
                this.$swal.close();
              });
          },
        }).then((result) => {
          console.log(result);
        });
      }
    },
    ver(value) {
      this.product = { ...value };
      this.displayVisor = true;
      console.info(this.product);
      this.$swal({
        width: 370,
        text: "esperar porfavor...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gstupa/SOLICITUD_ONE", this.product.solicitud.id)
            .then((res) => {
              console.info(res);
              this.$swal.close();
              this.selectSolicitud = res;
              this.selectSolicitud.recibirCorreo = this.selectSolicitud.recibirCorreo ? ["si"] : ""
              this.selectSolicitud.recibirFisico = this.selectSolicitud.recibirFisico ? ["si"] : ""
              this.selectSolicitud.fechaHora = this.formatFecha(this.selectSolicitud.fechaHora);
              
            })
            .catch((error) => {
              console.info(error);
              this.$swal.close();
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    finalizar(value) {
      this.product = { ...value };
      console.info(this.product);
      if (this.product.procesado == false) {
        this.$toast.add({
          severity: "error",
          summary: "Advertencia",
          detail: "debe recepcionar primero !",
          life: 3000,
        });
      } else {
        this.finalizarDialog = true;
      }
    },
    showRecepcion(value) {
      if (value === undefined) {
        return "SIN RECEPCION";
      } else {
        let val = new Date(value);
        var datestring =
          val.getDate() +
          "/" +
          (val.getMonth() + 1) +
          "/" +
          val.getFullYear() +
          " " +
          val.getHours() +
          ":" +
          val.getMinutes();
        return "RECEPCIONADO: " + datestring;
      }
    },
    showEstado(value) {
      console.info(value);
      if (value == "1") {
        return "REGISTRO NUEVO";
      } else if (value == "3") {
        return "FUE DERIVADO";
      } else return "";
    },
    formatFecha(value) {
      let val = new Date(value);
      var datestring =
        val.getDate() +
        "-" +
        (val.getMonth() + 1) +
        "-" +
        val.getFullYear() +
        " " +
        val.getHours() +
        ":" +
        val.getMinutes();

      return datestring;
    },
    myUploader(file) {
      /// console.info(file.files[0]);

      this.$swal({
        width: 370,
        text: "Subiendo archivo esperer porfavor...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gstupa/FILE_UPLOAD", file)
            .then((res) => {
              this.$swal.close();
              this.filetitulo = res.titulo;
              this.filetype = "[" + file.files[0].type + "]";
              this.product.tipoArchivo = file.files[0].type;
              this.product.ruta = res.destino;
            })
            .catch((error) => {
              this.$swal.close();
              this.fileTitulo = "error... " + error;
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    saveProduct() {
      this.submitted = true;
      let modelo = this.product;

      if (this.product.nombre && this.product.abreviatura) {
        this.isBusy = true;
        this.$swal({
          width: 370,
          text: "Cargando...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch("", modelo)
              .then((res) => {
                this.productDialog = false;
                this.isBusy = false;
                this.$swal.close();
                this.listarEntrada();
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail: "Entrada Actualizado id: " + res.id,
                  life: 3000,
                });
              })
              .catch((error) => {
                this.$swal.close();
                this.isBusy = false;
                this.error(error);
              });
          },
        }).then((result) => {
          console.log(result);
        });
      }
    },
    listarEntrada() {
      let params = {};
      this.isBusy = true;
      this.$swal({
        width: 370,
        text: "Cargando....",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gstupa/TRAMITE_ENTRADA_LIST", params)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.products = res === undefined ? [] : res;
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              this.error(error);
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },

    error(evt) {
      if (evt.response.status == 401 || evt.response.status == 500) {
        this.$swal(
          evt.response.data.errorMessage,
          "Ud. no tiene permitido esta función",
          "warning"
        );
      }
    },
    openNew() {
      this.action = "gstupa/DEPENDENCIA_SAVE";
      this.product = {
        idEjecutora: 0,
        nombre: "",
        abreviatura: "",
        orden: this.products.length + 1,
        estado: true,
      };
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },

    open(operacion) {
      if (operacion == "new") {
        this.product = {
          orden: this.products.length + 1,
          estado: true,
        };
      }
      let mutacion = {
        entrada: this.product,
        oper: operacion,
      };
      this.$store.commit("gstupa/OPERACION_DEPENDENCIA", mutacion);
      this.$router.push({
        name: "tupa/amigable/" + operacion,
      });
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  computed: {
    ...mapGetters({
      entrada: "gstupa/getModelEntrada",
      user: "gsdialogoauth/getUser",
    }),
  },

  mounted() {
   
    this.listarEntrada();
    this.listarEjecutora("");
  },
};
</script>

<style src="pdfvuer/dist/pdfvuer.css"></style>
<style lang="css" scoped>
#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* Page content */
.content {
  padding: 16px;
}
</style>

